// third party
import fetch from 'cross-fetch'
// utils
import RequestUtils from '../utils/RequestUtils'
import request from '../utils/request/Request' // new
// constants
import { API_HOST } from '../constants/EnvTypes'
import {
  REQUEST_MEDICOS, RECEIVE_MEDICOS, REQUEST_ALL_MEDICOS, RECEIVE_ALL_MEDICOS, REQUEST_MEDICO, RECEIVE_MEDICO,
  REQUEST_MEDICO_ESPECIALIDADES,  RECEIVE_MEDICO_ESPECIALIDADES, REQUEST_MEDICO_DOCUMENTOS, RECEIVE_MEDICO_DOCUMENTOS,
  REQUEST_ENVIAR_DOCUMENTO, RECEIVE_ENVIAR_DOCUMENTO, REQUEST_REMOVE_DOCUMENTO, RECEIVE_REMOVE_DOCUMENTO,
  REQUEST_DOCUMENTOS_MEDICOS_BY_CLINICA, RECEIVE_DOCUMENTOS_MEDICOS_BY_CLINICA, REQUEST_NOTAS_SERVICOS,
  RECEIVE_NOTAS_SERVICOS, REQUEST_SAVE_NOTA_SERVICOS, RECEIVE_SAVE_NOTA_SERVICOS, REQUEST_REMOVE_NOTA_SERVICOS,
  RECEIVE_REMOVE_NOTA_SERVICOS, RESET_ALL_MEDICO, RESET_MEDICO, REQUEST_SAVE_MEDICO, RECEIVE_SAVE_MEDICO,
  REQUEST_SAVE_MEDICO_ATENDIMENTO, RECEIVE_SAVE_MEDICO_ATENDIMENTO, REQUEST_CHANGE_NOTIFICATION_OPTIONS,
  RECEIVE_CHANGE_NOTIFICATION_OPTIONS, REQUEST_SAVE_PROFILE_MEDICO, RECEIVE_SAVE_PROFILE_MEDICO,
  REQUEST_SAVE_MEDICO_ENDERECOS, RECEIVE_SAVE_MEDICO_ENDERECOS, REQUEST_SAVE_AUTENTICAR_MEDICO,
  RECEIVE_SAVE_AUTENTICAR_MEDICO, REQUEST_REMOVE_MEDICO, RECEIVE_REMOVE_MEDICO, REQUEST_SAVE_SIGNATURE,
  RECEIVE_SAVE_SIGNATURE, REQUEST_SAVE_CONFIG_PLANTAO, RECEIVE_SAVE_CONFIG_PLANTAO, REQUEST_AVALIACOES,
  RECEIVE_AVALIACOES, REQUEST_AVALIACAO, RECEIVE_AVALIACAO, REQUEST_SAVE_AVALIACAO, RECEIVE_SAVE_AVALIACAO,
  REQUEST_SAVE_APTO_TELECONSULTA, RECEIVE_SAVE_APTO_TELECONSULTA
} from '../constants/ActionTypes'
// actions
import { fetchLoggedEntity } from './AuthActions'
import { addToast } from './ToastActions'
import { showRequestError } from './AppActions'


function requestMedicos() {
  return {
    type: REQUEST_MEDICOS,
    isLoading: true,
  }
}

function receiveMedicos(data) {
  return {
    type: RECEIVE_MEDICOS,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchMedicos(params = { page: 1, limit: 50 }, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch(requestMedicos())
    const endPoint = `${API_HOST}/medico`
    const url = RequestUtils.fetchURL(endPoint, params)
    const headers = RequestUtils.defaultHeaders()
    const options = {
      method: 'GET',
      headers: headers,
    }
    let responseCode = 0
    return fetch(url, options)
      .then(res => {
        responseCode = res.status
        return res.json()
      })
      .then(json => {
        return RequestUtils.responseHandler(dispatch, responseCode, json, {
          err400: 'Erro ao consultar médicos!',
          err401: 'Sessão expirada!',
          err403: 'Sem permisão para consultar médicos!',
          err500: 'Erro ao consultar médicos!',
          errDefault: 'Erro desconhecido ao consultar médicos!'
        })
      })
      .then(json => {
        dispatch(receiveMedicos(json))
        callback(true)
      })
      .catch(err => {
        dispatch(addToast('error', err.message, null, true))
        callback(false)
      })
  }
}

function requestAllMedicos() {
  return {
    type: REQUEST_ALL_MEDICOS.type,
    isLoading: true,
  }
}

function receiveAllMedicos(data) {
  return {
    type: RECEIVE_ALL_MEDICOS.type,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchAllMedicos() {
  return dispatch => {
    dispatch(requestAllMedicos())
    const url = `${API_HOST}/medico/all`
    request.get(url).then(json => {
      dispatch(receiveAllMedicos(json))
    }).catch(err => {
      dispatch(showRequestError(err))
    })
  }
}

/**
 * Busca medicos por nome ou id_especialidade incluindo suas ocupações de agenda.
 */
export function fetchSearchAgenda(params, callback = () => {}) {
  console.log('params', params)
  return dispatch => {
    const url = `${API_HOST}/medico/search-agenda`
    request.get(url, params).then(json => {
      callback(null, json)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(err)
      console.error(err)
    })
  }
}

function requestMedico() {
  return {
    type: REQUEST_MEDICO,
    isLoading: true,
  }
}

function receiveMedico(data) {
  return {
    type: RECEIVE_MEDICO,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

/**
 * Carrega medico por ID.
 *
 * @param {Number} id Id do medico.
 */
export function fetchMedico(id, callback = () => {}, params = null) {
  return dispatch => {
    dispatch(requestMedico())
    const endPoint = `${API_HOST}/medico/${id}`
    const url = params ? RequestUtils.fetchURL(endPoint, params) : endPoint
    const headers = RequestUtils.defaultHeaders()
    const options = {
      method: 'GET',
      headers: headers,
    }
    let responseCode = 0
    return fetch(url, options)
      .then(res => {
        responseCode = res.status
        return res.json()
      })
      .then(json => {
        return RequestUtils.responseHandler(dispatch, responseCode, json, {
          err400: 'Erro ao consultar profissional!',
          err401: 'Sessão expirada!',
          err403: 'Sem permisão para consultar profissional!',
          err500: 'Erro ao consultar profissional!',
          errDefault: 'Erro desconhecido ao consultar profissional!'
        })
      })
      .then(json => {
        dispatch(receiveMedico(json))
        callback(null, json)
      })
      .catch(err => {
        console.error(err.message)
        dispatch(addToast('error', 'Erro ao consultar profissional', null, true))
        dispatch(receiveMedico({}))
        callback(err)
      })
  }
}

function requestSaveMedico() {
  return {
    type: REQUEST_SAVE_MEDICO,
    isLoading: true,
  }
}

function receiveSaveMedico(data) {
  return {
    type: RECEIVE_SAVE_MEDICO,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

/**
 * Persiste os dados da medico. Se existir id atualiza(PUT),
 * caso contrário cria(POST).
 *
 * @param {Number} id
 * @param {Object} data
 */
export function saveMedico(id, data, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch(requestSaveMedico())
    const url = (id) ? `${API_HOST}/medico/${id}` : `${API_HOST}/medico`
    const headers = RequestUtils.defaultHeaders()
    const options = {
      method: (id) ? 'PUT' : 'POST',
      headers: headers,
      body: JSON.stringify(data),
    }
    let responseCode = 0
    return fetch(url, options)
      .then(res => {
        responseCode = res.status
        return res.json()
      })
      .then(json => {
        return RequestUtils.responseHandler(dispatch, responseCode, json, {
          err400: 'Erro ao salvar médico!',
          err401: 'Sessão expirada!',
          err403: 'Sem permisão para salvar médico!',
          err500: 'Erro ao salvar médico!',
          errDefault: 'Erro desconhecido ao salvar médico!'
        })
      })
      .then(json => {
        dispatch(addToast('success', 'Registro salvo com sucesso.', null, true))
        dispatch(receiveSaveMedico(json))
        let userType = getState().auth.jwtPayload.user.type
        if (userType === 'clinica') dispatch(fetchLoggedEntity(true))
        callback(true, json.id)
      })
      .catch(err => {
        dispatch(addToast('error', err.message, null, true))
        dispatch(receiveSaveMedico(data))
        callback(false)
      })
  }
}

function requestAvaliacoes(silent) {
  return {
    type: REQUEST_AVALIACOES,
    isLoading: silent ? false : true,
    silent,
  }
}

function receiveAvaliacoes(data, params) {
  console.log('receiveAvaliacoes', data)
  return {
    type: RECEIVE_AVALIACOES,
    isLoading: false,
    data,
    params,
    receivedAt: Date.now(),
  }
}

export function fetchAvaliacoes(params = { page: 1, limit: 50 }, silent = false, callback = () => {}) {
  return (dispatch, getState) => {
    const efetiveParams = silent ? getState().medico.avaliacoes.params : params
    dispatch(requestAvaliacoes(silent))
    request.get(`${API_HOST}/medico/rating`, efetiveParams).then(json => {
      dispatch(receiveAvaliacoes(json, efetiveParams))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestAvaliacao() {
  return {
    type: REQUEST_AVALIACAO,
    isLoading: true,
  }
}

function receiveAvaliacao(data) {
  return {
    type: RECEIVE_AVALIACAO,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchAvaliacao(id, callback = () => {}) {
  return dispatch => {
    dispatch(requestAvaliacao())
    request.get(`${API_HOST}/medico/rating/${id}`).then(json => {
      dispatch(receiveAvaliacao(json))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestSaveAvaliacao() {
  return {
    type: REQUEST_SAVE_AVALIACAO,
    isLoading: true,
  }
}

function receiveSaveAvaliacao(data) {
  return {
    type: RECEIVE_SAVE_AVALIACAO,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function saveAvaliacao(data, callback = () => {}) {
  return dispatch => {
    dispatch(requestSaveAvaliacao())
    request.put(`${API_HOST}/medico/rating/${data.id}`, {}, JSON.stringify(data)).then(json => {
      dispatch(receiveSaveAvaliacao(json))
      dispatch(fetchAvaliacoes({}, true))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

/**
 * Medico adiciona evento a sua agenda.
 *
 * @param {Number} idMedico
 * @param {Number} id
 * @param {Object} data
 */
export function saveMedicoAgenda(idMedico, id, data, callback = () => {}) {
  return dispatch => {
    dispatch(requestSaveMedico())
    const url = (id) ? `${API_HOST}/medico/${idMedico}/agenda/${id}` : `${API_HOST}/medico/${idMedico}/agenda`
    const headers = RequestUtils.defaultHeaders()
    const options = {
      method: (id) ? 'PUT' : 'POST',
      headers: headers,
      body: JSON.stringify(data),
    }
    let responseCode = 0
    return fetch(url, options)
      .then(res => {
        responseCode = res.status
        return res.json()
      })
      .then(json => {
        return RequestUtils.responseHandler(dispatch, responseCode, json, {
          err400: 'Erro ao salvar evento!',
          err401: 'Sessão expirada!',
          err403: 'Sem permisão para salvar evento!',
          err500: 'Erro ao salvar evento!',
          errDefault: 'Erro desconhecido ao salvar evento!'
        })
      })
      .then(json => {
        dispatch(addToast('success', 'Evento salvo com sucesso.', null, true))
        dispatch(receiveSaveMedico(json))
        callback(true)
      })
      .catch(err => {
        dispatch(addToast('error', err.message, null, true))
        dispatch(receiveSaveMedico())
        callback(false)
      })
  }
}

export function removeMedicoAgenda(idMedico, idAgenda, callback = () => {}) {
  return dispatch => {
    dispatch(requestSaveMedico())
    const url = `${API_HOST}/medico/${idMedico}/agenda/${idAgenda}`
    request.delete(url).then(json => {
      dispatch(addToast('success', 'Evento removido com sucesso.', null, true))
      dispatch(receiveSaveMedico())
      callback(true)
    }).catch(err => {
      dispatch(receiveSaveMedico(err))
      callback(false)
    })
  }
}

function requestSaveMedicoAtendimento() {
  return {
    type: REQUEST_SAVE_MEDICO_ATENDIMENTO,
    isLoading: true,
  }
}

function receiveSaveMedicoAtendimento(data) {
  return {
    type: RECEIVE_SAVE_MEDICO_ATENDIMENTO,
    isLoading: false,
    data,
    receivedAt: Date.now(),
  }
}

/**
 * Medico atualiza suas configurações de atendimento.
 *
 * @param {Number} id
 * @param {Object} data
 */
export function saveMedicoConfigAtendimento(idMedico, data, callback = () => {}) {
  return dispatch => {
    dispatch(requestSaveMedicoAtendimento())
    const url = `${API_HOST}/medico/${idMedico}/atendimento`
    request.put(url, {}, JSON.stringify(data)).then(json => {
      dispatch(addToast('success', 'Configurações salvas com sucesso.', null, true))
      dispatch(receiveSaveMedicoAtendimento(json))
      callback(true)
    }).catch(err => {
      dispatch(receiveSaveMedicoAtendimento())
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestSaveMedicoEnderecos() {
  return {
    type: REQUEST_SAVE_MEDICO_ENDERECOS,
    isLoading: true,
  }
}

function receiveSaveMedicoEnderecos() {
  return {
    type: RECEIVE_SAVE_MEDICO_ENDERECOS,
    isLoading: false,
    receivedAt: Date.now(),
  }
}

/**
 * Medico atualiza suas configurações de enderecos.
 *
 * @param {Number} idMedico
 * @param {Object} data
 */
export function saveMedicoConfigEnderecos(idMedico, data, callback = () => {}) {
  return dispatch => {
    dispatch(requestSaveMedicoEnderecos())
    const url = `${API_HOST}/medico/${idMedico}/enderecos`
    request.put(url, {}, JSON.stringify(data)).then(json => {
      dispatch(addToast('success', 'Configurações salvas com sucesso.', null, true))
      dispatch(receiveSaveMedicoEnderecos(json))
      callback(true)
    }).catch(err => {
      dispatch(receiveSaveMedicoEnderecos())
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

/**
 * Medico atualiza suas configurações de prescrição.
 *
 * @param {Number} id
 * @param {Object} data
 */
export function saveMedicoConfig(id, data) {
  return dispatch => {
    dispatch(requestSaveMedico())
    const url = `${API_HOST}/medico/${id}/config`
    const headers = RequestUtils.defaultHeaders()
    const options = {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(data),
    }
    let responseCode = 0
    return fetch(url, options)
      .then(res => {
        responseCode = res.status
        return res.json()
      })
      .then(json => {
        return RequestUtils.responseHandler(dispatch, responseCode, json, {
          err400: 'Erro ao salvar configurações!',
          err401: 'Sessão expirada!',
          err403: 'Sem permisão para salvar configurações!',
          err500: 'Erro ao salvar configurações!',
          errDefault: 'Erro desconhecido ao salvar configurações!'
        })
      })
      .then(json => {
        dispatch(addToast('success', 'Configurações salvas com sucesso.', null, true))
        dispatch(receiveSaveMedico(json))
        dispatch(fetchLoggedEntity(true))
      })
      .catch(err => {
        dispatch(addToast('error', err.message, null, true))
        dispatch(receiveSaveMedico())
      })
  }
}

function requestChangeNotificationOptions() {
  return {
    type: REQUEST_CHANGE_NOTIFICATION_OPTIONS,
    isLoading: true,
  }
}

function receiveChangeNotificationOptions() {
  return {
    type: RECEIVE_CHANGE_NOTIFICATION_OPTIONS,
    isLoading: false,
    receivedAt: Date.now(),
  }
}

export function fetchChangeNotificationOptions(data, callback = () => {}) {
  return dispatch => {
    dispatch(requestChangeNotificationOptions())
    const url = `${API_HOST}/medico/notification-options`
    const headers = RequestUtils.defaultHeaders()
    const options = {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(data),
    }
    let responseCode = 0
    return fetch(url, options)
      .then(res => {
        responseCode = res.status
        return res.json()
      })
      .then(json => {
        return RequestUtils.responseHandler(dispatch, responseCode, json, {
          err400: 'Erro ao salvar configurações!',
          err401: 'Sessão expirada!',
          err403: 'Sem permisão para salvar configurações!',
          err500: 'Erro ao salvar configurações!',
          errDefault: 'Erro desconhecido ao salvar configurações!'
        })
      })
      .then(json => {
        dispatch(addToast('success', 'Configurações salvas com sucesso.', null, true))
        dispatch(receiveChangeNotificationOptions())
        dispatch(fetchLoggedEntity(true))
        callback(true)
      })
      .catch(err => {
        dispatch(addToast('error', err.message, null, true))
        dispatch(receiveChangeNotificationOptions())
        callback(false)
      })
  }
}

function requestMedicoDocumentos() {
  return {
    type: REQUEST_MEDICO_DOCUMENTOS,
    isLoading: true,
  }
}

function receiveMedicoDocumentos(data) {
  return {
    type: RECEIVE_MEDICO_DOCUMENTOS,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchMedicoDocumentos(idMedico) {
  return (dispatch, getState) => {
    dispatch(requestMedicoDocumentos())
    const endPoint = `${API_HOST}/medico/${idMedico}/documentos`
    const url = RequestUtils.fetchURL(endPoint)
    const headers = RequestUtils.defaultHeaders()
    const options = {
      method: 'GET',
      headers: headers,
    }
    let responseCode = 0
    return fetch(url, options)
      .then(res => {
        responseCode = res.status
        return res.json()
      })
      .then(json => {
        return RequestUtils.responseHandler(dispatch, responseCode, json, {
          err400: 'Erro ao consultar documentos!',
          err401: 'Sessão expirada!',
          err403: 'Sem permisão para consultar documentos!',
          err500: 'Erro ao consultar documentos!',
          errDefault: 'Erro desconhecido ao consultar documentos!'
        })
      })
      .then(json => dispatch(receiveMedicoDocumentos(json)))
      .catch(err => {
        dispatch(addToast('error', err.message, null, true))
      })
  }
}

function requestEnviarDocumento() {
  return {
    type: REQUEST_ENVIAR_DOCUMENTO.type,
    isLoading: true,
  }
}

function receiveEnviarDocumento(data) {
  return {
    type: RECEIVE_ENVIAR_DOCUMENTO.type,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchEnviarDocumento(idMedico, data, callback = () => {}) {
  return dispatch => {
    dispatch(requestEnviarDocumento())
    const url = `${API_HOST}/medico/${idMedico}/documentos`
    request.post(url, {}, JSON.stringify(data), { requestId: REQUEST_ENVIAR_DOCUMENTO.id }).then(json => {
      dispatch(addToast('success', 'Documento enviado com sucesso.', null, true))
      dispatch(receiveEnviarDocumento(json))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestRemoveDocumento() {
  return {
    type: REQUEST_REMOVE_DOCUMENTO.type,
    isLoading: true,
  }
}

function receiveRemoveDocumento(data) {
  return {
    type: RECEIVE_REMOVE_DOCUMENTO.type,
    isLoading: false,
  }
}

export function fetchRemoveDocumento(idMedico, idDocumento, callback = () => {}) {
  return dispatch => {
    dispatch(requestRemoveDocumento())
    const url = `${API_HOST}/medico/${idMedico}/documentos/${idDocumento}`
    request.delete(url).then(json => {
      dispatch(addToast('success', 'Documento removido com sucesso.', null, true))
      dispatch(receiveRemoveDocumento())
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestDocumentosMedicosByClinica() {
  return {
    type: REQUEST_DOCUMENTOS_MEDICOS_BY_CLINICA,
    isLoading: true,
  }
}

function receiveDocumentosMedicosByClinica(data) {
  return {
    type: RECEIVE_DOCUMENTOS_MEDICOS_BY_CLINICA,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchDocumentosMedicosByClinica() {
  return dispatch => {
    dispatch(requestDocumentosMedicosByClinica())
    const endPoint = `${API_HOST}/medico/documentos/by-clinica`
    const url = RequestUtils.fetchURL(endPoint)
    const headers = RequestUtils.defaultHeaders()
    const options = {
      method: 'GET',
      headers: headers,
    }
    let responseCode = 0
    return fetch(url, options)
      .then(res => {
        responseCode = res.status
        return res.json()
      })
      .then(json => {
        return RequestUtils.responseHandler(dispatch, responseCode, json, {
          err400: 'Erro ao consultar documentos!',
          err401: 'Sessão expirada!',
          err403: 'Sem permisão para consultar documentos!',
          err500: 'Erro ao consultar documentos!',
          errDefault: 'Erro desconhecido ao consultar documentos!'
        })
      })
      .then(json => dispatch(receiveDocumentosMedicosByClinica(json)))
      .catch(err => {
        dispatch(addToast('error', err.message, null, true))
      })
  }
}

function requestMedicoEspecialidades() {
  return {
    type: REQUEST_MEDICO_ESPECIALIDADES,
    isLoading: true,
  }
}

function receiveMedicoEspecialidades(data) {
  return {
    type: RECEIVE_MEDICO_ESPECIALIDADES,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchMedicoEspecialidades(idMedico) {
  return (dispatch, getState) => {
    dispatch(requestMedicoEspecialidades())
    const endPoint = `${API_HOST}/medico/${idMedico}/especialidades`
    const url = RequestUtils.fetchURL(endPoint)
    const headers = RequestUtils.defaultHeaders()
    const options = {
      method: 'GET',
      headers: headers,
    }
    let responseCode = 0
    return fetch(url, options)
      .then(res => {
        responseCode = res.status
        return res.json()
      })
      .then(json => {
        return RequestUtils.responseHandler(dispatch, responseCode, json, {
          err400: 'Erro ao pesquisar especialidades médicas!',
          err401: 'Sessão expirada!',
          err403: 'Sem permisão para pesquisar especialidades médicas!',
          err500: 'Erro ao pesquisar especialidades médicas!',
          errDefault: 'Erro desconhecido ao pesquisar especialidades médicas!'
        })
      })
      .then(json => dispatch(receiveMedicoEspecialidades(json)))
      .catch(err => {
        dispatch(addToast('error', err.message, null, true))
      })
  }
}

function requestNotasServicos() {
  return {
    type: REQUEST_NOTAS_SERVICOS,
    isLoading: true,
  }
}

function receiveNotasServicos(data) {
  return {
    type: RECEIVE_NOTAS_SERVICOS,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchNotasServicos(idMedico, ano) {
  let params = {ano: ano}
  return (dispatch, getState) => {
    dispatch(requestNotasServicos())
    const endPoint = `${API_HOST}/medico/${idMedico}/notas-servicos`
    const url = RequestUtils.fetchURL(endPoint, params)
    const headers = RequestUtils.defaultHeaders()
    const options = {
      method: 'GET',
      headers: headers,
    }
    let responseCode = 0
    return fetch(url, options)
      .then(res => {
        responseCode = res.status
        return res.json()
      })
      .then(json => {
        return RequestUtils.responseHandler(dispatch, responseCode, json, {
          err400: 'Erro ao consultar notas de serviços!',
          err401: 'Sessão expirada!',
          err403: 'Sem permisão para consultar notas de serviços!',
          err500: 'Erro ao consultar produtividade!',
          errDefault: 'Erro desconhecido ao consultar notas de serviços!'
        })
      })
      .then(json => dispatch(receiveNotasServicos(json)))
      .catch(err => {
        dispatch(addToast('error', err.message, null, true))
      })
  }
}

function requestSaveNotaServicos() {
  return {
    type: REQUEST_SAVE_NOTA_SERVICOS,
    isLoading: true,
  }
}

function receiveSaveNotaServicos() {
  return {
    type: RECEIVE_SAVE_NOTA_SERVICOS,
    isLoading: false,
    receivedAt: Date.now(),
  }
}

/**
 * Cria um novo medico_nfs e move arquivo previamente enviado para diretório definitivo.
 *
 * @param {Number} idMedico
 * @param {Object} data
 * @param {Number} ano Utilizado apenas para o recarrega,ento da lista
 */
export function fetchSaveNotaServicos(idMedico, data, ano) {
  return dispatch => {
    dispatch(requestSaveNotaServicos())
    const endPoint = `${API_HOST}/medico/${idMedico}/notas-servicos`
    const url = RequestUtils.fetchURL(endPoint)
    const headers = RequestUtils.defaultHeaders()
    const options = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(data),
    }
    let responseCode = 0
    return fetch(url, options)
      .then(res => {
        responseCode = res.status
        return res.json()
      })
      .then(json => {
        return RequestUtils.responseHandler(dispatch, responseCode, json, {
          err400: 'Erro ao criar nota de serviços!',
          err401: 'Sessão expirada!',
          err403: 'Sem permisão para criar notas de serviços!',
          err500: 'Erro ao consultar produtividade!',
          errDefault: 'Erro desconhecido ao criar nota de serviços!'
        })
      })
      .then(json => {
        dispatch(receiveSaveNotaServicos(json))
        dispatch(fetchNotasServicos(idMedico, ano)) // recarrega lista
      })
      .catch(err => {
        dispatch(addToast('error', err.message, null, true))
      })
  }
}

function requestRemoveNotaServicos() {
  return {
    type: REQUEST_REMOVE_NOTA_SERVICOS,
    isLoading: true,
  }
}

function receiveRemoveNotaServicos() {
  return {
    type: RECEIVE_REMOVE_NOTA_SERVICOS,
    isLoading: false,
    receivedAt: Date.now(),
  }
}

/**
 * Cria um novo medico_nfs e move arquivo previamente enviado para diretório definitivo.
 *
 * @param {Number} idMedico
 * @param {Object} data
 * @param {Number} ano Utilizado apenas para o recarrega,ento da lista
 */
export function fetchRemoveNotaServicos(idMedico, idNfs, ano) {
  return dispatch => {
    dispatch(requestRemoveNotaServicos())
    const endPoint = `${API_HOST}/medico/${idMedico}/notas-servicos/${idNfs}`
    const url = RequestUtils.fetchURL(endPoint)
    const headers = RequestUtils.defaultHeaders()
    const options = {
      method: 'DELETE',
      headers: headers,
    }
    let responseCode = 0
    return fetch(url, options)
      .then(res => {
        responseCode = res.status
        return res.json()
      })
      .then(json => {
        return RequestUtils.responseHandler(dispatch, responseCode, json, {
          err400: 'Erro ao remover nota fiscal de serviços!',
          err401: 'Sessão expirada!',
          err403: 'Sem permisão para remover nota fiscal de serviços!',
          err500: 'Erro ao consultar produtividade!',
          errDefault: 'Erro desconhecido ao remover nota fiscal de serviços!'
        })
      })
      .then(json => {
        dispatch(receiveRemoveNotaServicos(json))
        dispatch(fetchNotasServicos(idMedico, ano)) // recarrega lista
      })
      .catch(err => {
        dispatch(addToast('error', err.message, null, true))
      })
  }
}

function requestSaveProfile() {
  return {
    type: REQUEST_SAVE_PROFILE_MEDICO,
    isLoading: true,
  }
}

function receiveSaveProfile() {
  return {
    type: RECEIVE_SAVE_PROFILE_MEDICO,
    isLoading: false,
  }
}

export function fetchSaveProfile(data, callback = () => {}) {
  return dispatch => {
    dispatch(requestSaveProfile())
    const url = `${API_HOST}/medico/profile`
    request.put(url, {}, JSON.stringify(data)).then(json => {
      dispatch(addToast('success', 'Perfil salvo com sucesso.', null, true))
      dispatch(receiveSaveProfile())
      callback()
    }).catch(err => {
      dispatch(showRequestError(err))
      dispatch(receiveSaveProfile())
      callback(err)
    })
  }
}

export function fetchInvite(data, callback = () => {}) {
  return dispatch => {
    const url = `${API_HOST}/medico/invite`
    request.put(url, {}, JSON.stringify(data)).then(json => {
      dispatch(addToast('success', 'Convite enviado com sucesso.', null, true))
      callback()
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(err)
    })
  }
}

function requestAutenticar() {
  return {
    type: REQUEST_SAVE_AUTENTICAR_MEDICO,
    isLoading: true,
  }
}

function receiveAutenticar() {
  return {
    type: RECEIVE_SAVE_AUTENTICAR_MEDICO,
    isLoading: false,
  }
}

export function fetchAutenticar(idMedico, data) {
  return dispatch => {
    dispatch(requestAutenticar())
    const url = `${API_HOST}/medico/${idMedico}/autenticar`
    request.put(url, {}, JSON.stringify(data)).then(json => {
      dispatch(addToast('success', 'Autenticação realizada com sucesso.', null, true))
      dispatch(receiveAutenticar())
      if (typeof window != 'undefined') { // em ambiente de teste nao existe
        window.router.push(`/medico?id_autenticacao_status=1`)
      }
    }).catch(err => {
      dispatch(showRequestError(err))
      dispatch(receiveAutenticar())
    })
  }
}

function requestSaveSignature() {
  return {
    type: REQUEST_SAVE_SIGNATURE,
    isLoading: true,
  }
}

function receiveSaveSignature() {
  return {
    type: RECEIVE_SAVE_SIGNATURE,
    isLoading: false,
  }
}

/**
 * Persiste os dados de layout da clinica.
 *
 * @param {Number} id
 * @param {Object} data
 */
export function saveSignature(data, callback = () => {}) {
  return dispatch => {
    dispatch(requestSaveSignature())
    const url = `${API_HOST}/medico/signature`
    request.put(url, {}, JSON.stringify(data)).then(json => {
      dispatch(addToast('success', 'Salvo com sucesso.', null, true))
      dispatch(receiveSaveSignature())
      dispatch(fetchLoggedEntity(true))
      callback()
    }).catch(err => {
      dispatch(receiveSaveSignature())
      dispatch(showRequestError(err))
      callback(err)
    })
  }
}

function requestSaveConfigPlantao() {
  return {
    type: REQUEST_SAVE_CONFIG_PLANTAO,
    isLoading: true,
  }
}

function receiveSaveConfigPlantao() {
  return {
    type: RECEIVE_SAVE_CONFIG_PLANTAO,
    isLoading: false,
  }
}

/**
 * Persiste  configurações de pantao clínico do médico.
 *
 * @param {Number} id
 * @param {Object} data
 */
export function saveConfigPlantao(data, callback = () => {}) {
  return dispatch => {
    dispatch(requestSaveConfigPlantao())
    const url = `${API_HOST}/medico/config-plantao`
    request.put(url, {}, JSON.stringify(data)).then(json => {
      dispatch(addToast('success', 'Salvo com sucesso.', null, true))
      dispatch(receiveSaveConfigPlantao())
      dispatch(fetchLoggedEntity(true))
      callback()
    }).catch(err => {
      dispatch(receiveSaveConfigPlantao())
      dispatch(showRequestError(err))
      callback(err)
    })
  }
}

function requestRemoveMedico() {
  return {
    type: REQUEST_REMOVE_MEDICO,
    isLoading: true,
  }
}

function receiveRemoveMedico() {
  return {
    type: RECEIVE_REMOVE_MEDICO,
    isLoading: false,
  }
}

export function removeMedico(idMedico, callback = () => {}) {
  return dispatch => {
    dispatch(requestRemoveMedico())
    const url = `${API_HOST}/medico/${idMedico}`
    request.delete(url).then(json => {
      dispatch(addToast('success', 'Profissional removido com sucesso.', null, true))
      dispatch(receiveRemoveMedico())
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      dispatch(receiveRemoveMedico())
      callback(false)
    })
  }
}

function requestSaveAptoTeleconsulta() {
  return {
    type: REQUEST_SAVE_APTO_TELECONSULTA,
    isLoading: true,
  }
}

function receiveSaveAptoTeleconsulta() {
  return {
    type: RECEIVE_SAVE_APTO_TELECONSULTA,
    isLoading: false,
  }
}

export function fetchSaveAptoTeleconsulta() {
  return (dispatch, getState) => {
    dispatch(requestSaveAptoTeleconsulta())
    const state = getState()
    const idMedico = state.auth.loggedEntity.data.id
    const url = `${API_HOST}/medico/${idMedico}/apto-teleconsulta`
    request.put(url).then(json => {
      dispatch(addToast('success', 'Agenda liberada com sucesso.', null, true))
      dispatch(fetchLoggedEntity(true))
      dispatch(receiveSaveAptoTeleconsulta())
    }).catch(err => {
      dispatch(showRequestError(err))
      dispatch(receiveSaveAptoTeleconsulta())
    })
  }
}

export function resetAllMedico() {
  return {
    type: RESET_ALL_MEDICO,
  }
}

export function resetMedico() {
  return {
    type: RESET_MEDICO,
  }
}
