// utils
import request from '../utils/request/Request'
import RequestUtils from '../utils/RequestUtils'
// constants
import { API_HOST } from '../constants/EnvTypes'
import {
  REQUEST_CLINICAS, RECEIVE_CLINICAS, REQUEST_CLINICAS_MATRIZ, RECEIVE_CLINICAS_MATRIZ, REQUEST_CLINICA_MATRIZ,
  RECEIVE_CLINICA_MATRIZ, REQUEST_SAVE_CLINICA_MATRIZ, RECEIVE_SAVE_CLINICA_MATRIZ, REQUEST_ALL_CLINICAS,
  RECEIVE_ALL_CLINICAS, REQUEST_CLINICA, RECEIVE_CLINICA, REQUEST_SAVE_CLINICA, RECEIVE_SAVE_CLINICA,
  REQUEST_REMOVE_CLINICA, RECEIVE_REMOVE_CLINICA, REQUEST_CLINICA_ESPECIALIDADES, RECEIVE_CLINICA_ESPECIALIDADES,
  REQUEST_CLINICA_IDIOMAS, RECEIVE_CLINICA_IDIOMAS, REQUEST_ADD_CREDIT, RECEIVE_ADD_CREDIT,
  REQUEST_SAVE_CLINICA_ESPECIALIDADE, RECEIVE_SAVE_CLINICA_ESPECIALIDADE, REQUEST_REMOVE_CLINICA_ESPECIALIDADE,
  RECEIVE_REMOVE_CLINICA_ESPECIALIDADE, REQUEST_CLINICA_DOCUMENTOS, RECEIVE_CLINICA_DOCUMENTOS,
  REQUEST_ENVIAR_DOCUMENTO_CLINICA, RECEIVE_ENVIAR_DOCUMENTO_CLINICA, REQUEST_REMOVE_DOCUMENTO_CLINICA,
  RECEIVE_REMOVE_DOCUMENTO_CLINICA, REQUEST_SAVE_CUSTOMER_SUCCESS, RECEIVE_SAVE_CUSTOMER_SUCCESS,
  REQUEST_CLINICA_VALORES_RECEBER, RECEIVE_CLINICA_VALORES_RECEBER, REQUEST_CLINICA_RECIBOS_DE_TAXAS,
  RECEIVE_CLINICA_RECIBOS_DE_TAXAS, REQUEST_SAVE_PROFILE_CLINICA, RECEIVE_SAVE_PROFILE_CLINICA,
  REQUEST_SAVE_AUTENTICAR_CLINICA, RECEIVE_SAVE_AUTENTICAR_CLINICA, REQUEST_SAVE_CLINICA_LAYOUT,
  RECEIVE_SAVE_CLINICA_LAYOUT, REQUEST_SAVE_CLINICA_PAGAMENTO, RECEIVE_SAVE_CLINICA_PAGAMENTO,
  REQUEST_CONFIG_NOTIFICACAO_CREDITO, RECEIVE_CONFIG_NOTIFICACAO_CREDITO, REQUEST_SAVE_CONFIG_NOTIFICACAO_CREDITO,
  RECEIVE_SAVE_CONFIG_NOTIFICACAO_CREDITO, RESET_CLINICA, RESET_ALL_CLINICA
} from '../constants/ActionTypes'
// actions
import { addToast } from './ToastActions'
import { showRequestError } from './AppActions'
import { fetchLoggedEntity } from './AuthActions'

function requestClinicas() {
  return {
    type: REQUEST_CLINICAS.type,
    isLoading: true,
  }
}

function receiveClinicas(data) {
  return {
    type: RECEIVE_CLINICAS.type,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchClinicas(params = { page: 1, limit: 50 }, callback = () => {}) {
  return dispatch => {
    dispatch(requestClinicas())
    request.get(`${API_HOST}/clinica`, params, { requestId: REQUEST_CLINICAS.id }).then(json => {
      dispatch(receiveClinicas(json))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestAllClinicas() {
  return {
    type: REQUEST_ALL_CLINICAS.type,
    isLoading: true,
  }
}

function receiveAllClinicas(data) {
  return {
    type: RECEIVE_ALL_CLINICAS.type,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchAllClinicas(callback = () => {}) {
  return dispatch => {
    dispatch(requestAllClinicas())
    request.get(`${API_HOST}/clinica/all`, {}, { requestId: REQUEST_ALL_CLINICAS.id }).then(json => {
      dispatch(receiveAllClinicas(json))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestClinicasMatriz() {
  return {
    type: REQUEST_CLINICAS_MATRIZ.type,
    isLoading: true,
  }
}

function receiveClinicasMatriz(data) {
  return {
    type: RECEIVE_CLINICAS_MATRIZ.type,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchClinicasMatriz(params = { page: 1, limit: 50 }, callback = () => {}) {
  return dispatch => {
    dispatch(requestClinicasMatriz())
    request.get(`${API_HOST}/clinica/matriz`, params, { requestId: REQUEST_CLINICAS_MATRIZ.id }).then(json => {
      dispatch(receiveClinicasMatriz(json))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestClinica() {
  return {
    type: REQUEST_CLINICA.type,
    isLoading: true,
  }
}

function receiveClinica(data) {
  return {
    type: RECEIVE_CLINICA.type,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

/**
 * Carrega clinica por ID.
 *
 * @param {Number} id Id da clinica.
 */
export function fetchClinica(id, callback = () => {}) {
  return dispatch => {
    dispatch(requestClinica())
    request.get(`${API_HOST}/clinica/${id}`, {}, { requestId: REQUEST_CLINICA.id }).then(json => {
      dispatch(receiveClinica(json))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestClinicaMatriz() {
  return {
    type: REQUEST_CLINICA_MATRIZ.type,
    isLoading: true,
  }
}

function receiveClinicaMatriz(data) {
  return {
    type: RECEIVE_CLINICA_MATRIZ.type,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

/**
 * Carrega clinica por ID.
 *
 * @param {Number} id Id da clinica.
 */
export function fetchClinicaMatriz(id, callback = () => {}) {
  return dispatch => {
    dispatch(requestClinicaMatriz())
    request.get(`${API_HOST}/clinica/matriz/${id}`, {}, { requestId: REQUEST_CLINICA_MATRIZ.id }).then(json => {
      dispatch(receiveClinicaMatriz(json))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestClinicaDocumentos() {
  return {
    type: REQUEST_CLINICA_DOCUMENTOS.type,
    isLoading: true,
  }
}

function receiveClinicaDocumentos(data) {
  return {
    type: RECEIVE_CLINICA_DOCUMENTOS.type,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchClinicaDocumentos(idClinica) {
  return dispatch => {
    dispatch(requestClinicaDocumentos())
    const endPoint = `${API_HOST}/clinica/${idClinica}/documentos`
    const url = RequestUtils.fetchURL(endPoint)
    const headers = RequestUtils.defaultHeaders()
    const options = {
      method: 'GET',
      headers: headers,
    }
    let responseCode = 0
    return fetch(url, options)
      .then(res => {
        responseCode = res.status
        return res.json()
      })
      .then(json => {
        return RequestUtils.responseHandler(dispatch, responseCode, json, {
          err400: 'Erro ao consultar documentos!',
          err401: 'Sessão expirada!',
          err403: 'Sem permisão para consultar documentos!',
          err500: 'Erro ao consultar documentos!',
          errDefault: 'Erro desconhecido ao consultar documentos!'
        })
      })
      .then(json => dispatch(receiveClinicaDocumentos(json)))
      .catch(err => {
        dispatch(addToast('error', err.message, null, true))
      })
  }
}

function requestEnviarDocumento() {
  return {
    type: REQUEST_ENVIAR_DOCUMENTO_CLINICA.type,
    isLoading: true,
  }
}

function receiveEnviarDocumento(data) {
  return {
    type: RECEIVE_ENVIAR_DOCUMENTO_CLINICA.type,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchEnviarDocumento(idClinica, data, callback = () => {}) {
  return dispatch => {
    dispatch(requestEnviarDocumento())
    const url = `${API_HOST}/clinica/${idClinica}/documentos`
    request.post(url, {}, JSON.stringify(data), { requestId: REQUEST_ENVIAR_DOCUMENTO_CLINICA.id }).then(json => {
      dispatch(addToast('success', 'Documento enviado com sucesso.', null, true))
      dispatch(receiveEnviarDocumento(json))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestRemoveDocumento() {
  return {
    type: REQUEST_REMOVE_DOCUMENTO_CLINICA.type,
    isLoading: true,
  }
}

function receiveRemoveDocumento() {
  return {
    type: RECEIVE_REMOVE_DOCUMENTO_CLINICA.type,
    isLoading: false,
  }
}

export function fetchRemoveDocumento(idClinica, idDocumento, callback = () => {}) {
  return dispatch => {
    dispatch(requestRemoveDocumento())
    const url = `${API_HOST}/clinica/${idClinica}/documentos/${idDocumento}`
    request.delete(url).then(json => {
      dispatch(addToast('success', 'Documento removido com sucesso.', null, true))
      dispatch(receiveRemoveDocumento())
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestSaveClinica() {
  return {
    type: REQUEST_SAVE_CLINICA.type,
    isLoading: true,
  }
}

function receiveSaveClinica() {
  return {
    type: RECEIVE_SAVE_CLINICA.type,
    isLoading: false,
    receivedAt: Date.now(),
  }
}

/**
 * Persiste os dados da clinica. Se existir id atualiza(PUT),
 * caso contrário cria(POST).
 *
 * @param {Number} id
 * @param {Object} data
 */
export function saveClinica(id, data, callback = () => {}) {
  return dispatch => {
    dispatch(requestSaveClinica())
    const url = (id) ? `${API_HOST}/clinica/${id}` : `${API_HOST}/clinica`
    const method = (id) ? 'put' : 'post'
    request[method](url, {}, JSON.stringify(data), { requestId: REQUEST_SAVE_CLINICA.id }).then(json => {
      dispatch(addToast('success', 'Clínica salva com sucesso.', null, true))
      dispatch(receiveSaveClinica())
      callback(true, json.id)
    }).catch(err => {
      dispatch(receiveSaveClinica())
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestSaveClinicaMatriz() {
  return {
    type: REQUEST_SAVE_CLINICA_MATRIZ.type,
    isLoading: true,
  }
}

function receiveSaveClinicaMatriz() {
  return {
    type: RECEIVE_SAVE_CLINICA_MATRIZ.type,
    isLoading: false
  }
}

/**
 * Cria clinica matriz.
 *
 * @param {Number} id
 * @param {Object} data
 */
export function saveClinicaMatriz(data, callback = () => {}) {
  return dispatch => {
    dispatch(requestSaveClinicaMatriz())
    const url = `${API_HOST}/clinica/matriz`
    request.post(url, {}, JSON.stringify(data), { requestId: REQUEST_SAVE_CLINICA_MATRIZ.id }).then(json => {
      dispatch(addToast('success', 'Clinica matriz criada com sucesso.', null, true))
      dispatch(receiveSaveClinicaMatriz(json))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestSaveCustomerSuccess() {
  return {
    type: REQUEST_SAVE_CUSTOMER_SUCCESS.type,
    isLoading: true,
  }
}

function receiveSaveCustomerSuccess() {
  return {
    type: RECEIVE_SAVE_CUSTOMER_SUCCESS.type,
    isLoading: false
  }
}

/**
 * Cria clinica matriz.
 *
 * @param {Number} id
 * @param {Object} data
 */
export function saveCustomerSuccess(idClinica, data, callback = () => {}) {
  return dispatch => {
    dispatch(requestSaveCustomerSuccess())
    const url = `${API_HOST}/clinica/${idClinica}/customer-success`
    request.post(url, {}, JSON.stringify(data), { requestId: REQUEST_SAVE_CUSTOMER_SUCCESS.id }).then(json => {
      dispatch(addToast('success', 'Informações adicionadas com sucesso.', null, true))
      dispatch(receiveSaveCustomerSuccess(json))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestRemoveClinica() {
  return {
    type: REQUEST_REMOVE_CLINICA.type,
    isLoading: true,
  }
}

function receiveRemoveClinica() {
  return {
    type: RECEIVE_REMOVE_CLINICA.type,
    isLoading: false,
  }
}

export function fetchRemoveClinica(id, callback = () => {}) {
  return dispatch => {
    dispatch(requestRemoveClinica())
    const url = `${API_HOST}/clinica/${id}`
    request.delete(url, {}, '', { requestId: REQUEST_REMOVE_CLINICA.id }).then(json => {
      dispatch(addToast('success', 'Clinica removida com sucesso.', null, true))
      dispatch(receiveRemoveClinica(json))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestClinicaEspecialidades() {
  return {
    type: REQUEST_CLINICA_ESPECIALIDADES.type,
    isLoading: true,
  }
}

function receiveClinicaEspecialidades(data) {
  return {
    type: RECEIVE_CLINICA_ESPECIALIDADES.type,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchClinicaEspecialidades(idClinica, fromCache = true, callback = () => {}) {
  return (dispatch, getState) => {
    const isLoaded = (getState().clinica.clinicaEspecialidades.receivedAt !== null)
    // carrega apenas uma vez
    if (! fromCache || ! isLoaded) {
      dispatch(requestClinicaEspecialidades())
      const url = `${API_HOST}/clinica/${idClinica}/especialidades`
      request.get(url, {}, { requestId: REQUEST_CLINICA_ESPECIALIDADES.id }).then(json => {
        dispatch(receiveClinicaEspecialidades(json))
        callback(true)
      }).catch(err => {
        dispatch(showRequestError(err))
        callback(false)
      })
    }
  }
}

function requestSaveClinicaEspecialidade() {
  return {
    type: REQUEST_SAVE_CLINICA_ESPECIALIDADE.type,
    isLoading: true,
  }
}

function receiveSaveClinicaEspecialidade(data) {
  return {
    type: RECEIVE_SAVE_CLINICA_ESPECIALIDADE.type,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

/**
 * Persiste os dados da clinica. Se existir id atualiza(PUT),
 * caso contrário cria(POST).
 *
 * @param {Number} id
 * @param {Object} data
 */
export function saveClinicaEspecialidade(idClinica, idClinicaEspecialidade, data, callback = () => {}) {
  return dispatch => {
    dispatch(requestSaveClinicaEspecialidade())
    const url = (idClinicaEspecialidade)
      ? `${API_HOST}/clinica/${idClinica}/especialidades/${idClinicaEspecialidade}`
      : `${API_HOST}/clinica/${idClinica}/especialidades`
    const method = (idClinicaEspecialidade) ? 'put' : 'post'
    request[method](url, {}, JSON.stringify(data), { requestId: REQUEST_SAVE_CLINICA_ESPECIALIDADE.id }).then(json => {
      dispatch(addToast('success', 'Registro salvo com sucesso.', null, true))
      dispatch(receiveSaveClinicaEspecialidade(json))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestRemoveClinicaEspecialidade() {
  return {
    type: REQUEST_REMOVE_CLINICA_ESPECIALIDADE.type,
    isLoading: true,
  }
}

function receiveRemoveClinicaEspecialidade() {
  return {
    type: RECEIVE_REMOVE_CLINICA_ESPECIALIDADE.type,
    isLoading: false,
  }
}

export function fetchRemoveClinicaEspecialidade(idClinica, idClinicaEspecialidade, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch(requestRemoveClinicaEspecialidade())
    const url = `${API_HOST}/clinica/${idClinica}/especialidades/${idClinicaEspecialidade}`
    request.delete(url, {}, '', { requestId: REQUEST_REMOVE_CLINICA_ESPECIALIDADE.id }).then(json => {
      dispatch(addToast('success', 'Registro removido com sucesso.', null, true))
      dispatch(receiveRemoveClinicaEspecialidade())
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestIdiomas() {
  return {
    type: REQUEST_CLINICA_IDIOMAS.type,
    isLoading: true,
  }
}

function receiveIdiomas(data) {
  return {
    type: RECEIVE_CLINICA_IDIOMAS.type,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchIdiomas(callback = () => {}) {
  return (dispatch, getState) => {
    const isLoaded = (getState().clinica.idiomas.receivedAt !== null)
    // carrega apenas uma vez
    if (! isLoaded) {
      dispatch(requestIdiomas())
      const url = `${API_HOST}/clinica/idiomas`
      request.get(url, {}, { requestId: REQUEST_CLINICA_IDIOMAS.id }).then(json => {
        dispatch(receiveIdiomas(json))
        callback(true)
      }).catch(err => {
        dispatch(showRequestError(err))
        callback(false)
      })
    }
  }
}

function requestAddCredit() {
  return {
    type: REQUEST_ADD_CREDIT.type,
    isLoading: true,
  }
}

function receiveAddCredit(data) {
  return {
    type: RECEIVE_ADD_CREDIT.type,
    isLoading: false,
  }
}

/**
 * Cria uma nova fatura para adição de crédito.
 * Esta action nã altera o state.
 *
 *
 * @param {Number} id
 * @param {Object} data { value: 100.00 }
 *
 */
export function addCredit(data, callback = () => {}) {
  return dispatch => {
    dispatch(requestAddCredit())
    const url = `${API_HOST}/clinica/recarga-prepago`
    request.post(url, {}, JSON.stringify(data), { requestId: REQUEST_ADD_CREDIT.id }).then(json => {
      dispatch(addToast('success', 'Fatura para adição de crédito criada com sucesso.', null, true))
      dispatch(receiveAddCredit())
      callback(json.id)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

/**
 * Adiciona credito incondicionalmente e diretamente(sem fatura) a uma determinada clinica.
 *
 * @param {Object} data { value: 100.00, id_clinica: 1 }
 *
 */
export function addCreditUnconditional(data, callback = () => {}) {
  return (dispatch) => {
    const url = `${API_HOST}/clinica/recarga-prepago-unconditional`
    request.post(url, {}, JSON.stringify(data), { requestId: REQUEST_SAVE_CLINICA_MATRIZ.id }).then(json => {
      dispatch(addToast('success', 'Operação de créditos realizada com sucesso.', null, true))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestConfigNotificacaoCredito() {
  return {
    type: REQUEST_CONFIG_NOTIFICACAO_CREDITO,
    isLoading: true,
  }
}

function receiveConfigNotificacaoCredito(data) {
  return {
    type: RECEIVE_CONFIG_NOTIFICACAO_CREDITO,
    isLoading: false,
    data,
    receivedAt: Date.now(),
  }
}

/**
 * Obtem configurações de notificações de crédito.
 */
export function fetchConfigNotificacaoCredito(id, callback = () => {}) {
  return dispatch => {
    dispatch(requestConfigNotificacaoCredito())
    const url = `${API_HOST}/clinica/${id}/config-notificacoes-credito`
    request.get(url).then(json => {
      dispatch(receiveConfigNotificacaoCredito(json))
      callback(true, json.id)
    }).catch(err => {
      dispatch(receiveConfigNotificacaoCredito())
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestSaveConfigNotificacaoCredito() {
  return {
    type: REQUEST_SAVE_CONFIG_NOTIFICACAO_CREDITO,
    isLoading: true,
  }
}

function receiveSaveConfigNotificacaoCredito() {
  return {
    type: RECEIVE_SAVE_CONFIG_NOTIFICACAO_CREDITO,
    isLoading: false,
    receivedAt: Date.now(),
  }
}

/**
 * Persiste configurações de notificações de crédito(PUT).
 *
 * @param {Number} id
 * @param {Object} data
 */
export function saveConfigNotificacaoCredito(id, data, callback = () => {}) {
  return dispatch => {
    dispatch(requestSaveConfigNotificacaoCredito())
    const url = `${API_HOST}/clinica/${id}/config-notificacoes-credito`
    request.put(url, {}, JSON.stringify(data)).then(json => {
      dispatch(addToast('success', 'Configurações salvas com sucesso.', null, true))
      dispatch(receiveSaveConfigNotificacaoCredito())
      dispatch(fetchLoggedEntity(true))
      callback(null, json)
    }).catch(err => {
      dispatch(receiveSaveConfigNotificacaoCredito())
      dispatch(showRequestError(err))
      callback(err)
    })
  }
}

function requeValoresReceber() {
  return {
    type: REQUEST_CLINICA_VALORES_RECEBER,
    isLoading: true,
  }
}

function receiveValoresReceber(data) {
  return {
    type: RECEIVE_CLINICA_VALORES_RECEBER,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchValoresReceber(idClinica, ano) {
  let params = {ano: ano}
  return dispatch => {
    dispatch(requeValoresReceber())
    const endPoint = `${API_HOST}/clinica/${idClinica}/valores-receber`
    const url = RequestUtils.fetchURL(endPoint, params)
    const headers = RequestUtils.defaultHeaders()
    const options = {
      method: 'GET',
      headers: headers,
    }
    let responseCode = 0
    return fetch(url, options)
      .then(res => {
        responseCode = res.status
        return res.json()
      })
      .then(json => {
        return RequestUtils.responseHandler(dispatch, responseCode, json, {
          err400: 'Erro ao consultar valores a receber!',
          err401: 'Sessão expirada!',
          err403: 'Sem permisão para consultar valores a receber!',
          err500: 'Erro ao consultar produtividade!',
          errDefault: 'Erro desconhecido ao consultar valores a receber!'
        })
      })
      .then(json => dispatch(receiveValoresReceber(json)))
      .catch(err => {
        dispatch(addToast('error', err.message, null, true))
        dispatch(receiveValoresReceber())
      })
  }
}

function requeRecibosDeTaxas() {
  return {
    type: REQUEST_CLINICA_RECIBOS_DE_TAXAS,
    isLoading: true,
  }
}

function receiveRecibosDeTaxas(data) {
  return {
    type: RECEIVE_CLINICA_RECIBOS_DE_TAXAS,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchRecibosDeTaxas(idClinica, ano) {
  let params = {ano: ano}
  return dispatch => {
    dispatch(requeRecibosDeTaxas())
    const endPoint = `${API_HOST}/clinica/${idClinica}/recibos-taxas`
    const url = RequestUtils.fetchURL(endPoint, params)
    const headers = RequestUtils.defaultHeaders()
    const options = {
      method: 'GET',
      headers: headers,
    }
    let responseCode = 0
    return fetch(url, options)
      .then(res => {
        responseCode = res.status
        return res.json()
      })
      .then(json => {
        return RequestUtils.responseHandler(dispatch, responseCode, json, {
          err400: 'Erro ao consultar recibos de taxas!',
          err401: 'Sessão expirada!',
          err403: 'Sem permisão para consultar recibos de taxas!',
          err500: 'Erro ao consultar produtividade!',
          errDefault: 'Erro desconhecido ao consultar recibos de taxas!'
        })
      })
      .then(json => dispatch(receiveRecibosDeTaxas(json)))
      .catch(err => {
        dispatch(addToast('error', err.message, null, true))
        dispatch(receiveRecibosDeTaxas())
      })
  }
}

function requestSaveProfile() {
  return {
    type: REQUEST_SAVE_PROFILE_CLINICA,
    isLoading: true,
  }
}

function receiveSaveProfile() {
  return {
    type: RECEIVE_SAVE_PROFILE_CLINICA,
    isLoading: false,
  }
}

export function fetchSaveProfile(data, callback = () => {}) {
  return dispatch => {
    dispatch(requestSaveProfile())
    const url = `${API_HOST}/clinica/profile`
    request.put(url, {}, JSON.stringify(data)).then(json => {
      dispatch(addToast('success', 'Perfil salvo com sucesso.', null, true))
      dispatch(receiveSaveProfile())
      callback()
    }).catch(err => {
      dispatch(showRequestError(err))
      dispatch(receiveSaveProfile())
      callback(err)
    })
  }
}

function requestAutenticar() {
  return {
    type: REQUEST_SAVE_AUTENTICAR_CLINICA,
    isLoading: true,
  }
}

function receiveAutenticar() {
  return {
    type: RECEIVE_SAVE_AUTENTICAR_CLINICA,
    isLoading: false,
  }
}

export function fetchAutenticar(idClinica, data) {
  return dispatch => {
    dispatch(requestAutenticar())
    const url = `${API_HOST}/clinica/${idClinica}/autenticar`
    request.put(url, {}, JSON.stringify(data)).then(json => {
      dispatch(addToast('success', 'Autenticação realizada com sucesso.', null, true))
      dispatch(receiveAutenticar())
      if (typeof window != 'undefined') { // em ambiente de teste nao existe
        window.router.push(`/clinica?id_autenticacao_status=1`)
      }
    }).catch(err => {
      dispatch(showRequestError(err))
      dispatch(receiveAutenticar())
    })
  }
}

function requestSaveClinicaLayout() {
  return {
    type: REQUEST_SAVE_CLINICA_LAYOUT,
    isLoading: true,
  }
}

function receiveSaveClinicaLayout() {
  return {
    type: RECEIVE_SAVE_CLINICA_LAYOUT,
    isLoading: false,
    receivedAt: Date.now(),
  }
}

/**
 * Persiste os dados de layout da clinica.
 *
 * @param {Number} id
 * @param {Object} data
 */
export function saveClinicaLayout(data, callback = () => {}) {
  return dispatch => {
    dispatch(requestSaveClinicaLayout())
    const url = `${API_HOST}/clinica/layout`
    request.put(url, {}, JSON.stringify(data)).then(json => {
      dispatch(addToast('success', 'Salvo com sucesso.', null, true))
      dispatch(receiveSaveClinicaLayout())
      dispatch(fetchLoggedEntity(true))
      callback()
    }).catch(err => {
      dispatch(receiveSaveClinicaLayout())
      dispatch(showRequestError(err))
      callback(err)
    })
  }
}

function requestSaveClinicaPagamento() {
  return {
    type: REQUEST_SAVE_CLINICA_PAGAMENTO,
    isLoading: true,
  }
}

function receiveSaveClinicaPagamento() {
  return {
    type: RECEIVE_SAVE_CLINICA_PAGAMENTO,
    isLoading: false,
    receivedAt: Date.now(),
  }
}

/**
 * Persiste configurações de pagamento da clinica.
 *
 * @param {Object} data
 */
export function saveClinicaPagamento(data, callback = () => {}) {
  return dispatch => {
    dispatch(requestSaveClinicaPagamento())
    const url = `${API_HOST}/clinica/pagamento`
    request.put(url, {}, JSON.stringify(data)).then(json => {
      dispatch(addToast('success', 'Salvo com sucesso.', null, true))
      dispatch(receiveSaveClinicaPagamento())
      dispatch(fetchLoggedEntity(true))
      callback()
    }).catch(err => {
      dispatch(receiveSaveClinicaPagamento())
      dispatch(showRequestError(err))
      callback(err)
    })
  }
}

export function resetClinica() {
  return {
    type: RESET_CLINICA.type,
  }
}

export function resetAllClinica() {
  return {
    type: RESET_ALL_CLINICA.type,
  }
}
