import {
  REQUEST_ALL_ESPECIALIDADES, RECEIVE_ALL_ESPECIALIDADES, REQUEST_ALL_ESPECIALIDADES_TELE,
  RECEIVE_ALL_ESPECIALIDADES_TELE, REQUEST_ALL_ESPECIALIDADES_AUTOSETUP, RECEIVE_ALL_ESPECIALIDADES_AUTOSETUP,
  REQUEST_ESPECIALIDADES, RECEIVE_ESPECIALIDADES, REQUEST_ESPECIALIDADE, RECEIVE_ESPECIALIDADE,
  REQUEST_SAVE_ESPECIALIDADE, RECEIVE_SAVE_ESPECIALIDADE, REQUEST_AJUSTE_VALOR, RECEIVE_AJUSTE_VALOR,
  RESET_ESPECIALIDADE, REQUEST_ESPECIALIDADES_MACRO, RECEIVE_ESPECIALIDADES_MACRO, REQUEST_ESPECIALIDADE_MACRO,
  RECEIVE_ESPECIALIDADE_MACRO, REQUEST_SAVE_ESPECIALIDADE_MACRO, RECEIVE_SAVE_ESPECIALIDADE_MACRO,
  REQUEST_DELETE_ESPECIALIDADE_MACRO, RECEIVE_DELETE_ESPECIALIDADE_MACRO, RESET_ESPECIALIDADE_MACRO,
  REQUEST_ESPECIALIDADES_TELE, RECEIVE_ESPECIALIDADES_TELE, REQUEST_ESPECIALIDADE_TELE, RECEIVE_ESPECIALIDADE_TELE,
  REQUEST_SAVE_ESPECIALIDADE_TELE, RECEIVE_SAVE_ESPECIALIDADE_TELE, REQUEST_DELETE_ESPECIALIDADE_TELE,
  RECEIVE_DELETE_ESPECIALIDADE_TELE, RESET_ESPECIALIDADE_TELE, REQUEST_ESPECIALIDADES_AUTOSETUP,
  RECEIVE_ESPECIALIDADES_AUTOSETUP, REQUEST_ESPECIALIDADE_AUTOSETUP, RECEIVE_ESPECIALIDADE_AUTOSETUP,
  REQUEST_SAVE_ESPECIALIDADE_AUTOSETUP, RECEIVE_SAVE_ESPECIALIDADE_AUTOSETUP, REQUEST_DELETE_ESPECIALIDADE_AUTOSETUP,
  RECEIVE_DELETE_ESPECIALIDADE_AUTOSETUP, RESET_ESPECIALIDADE_AUTOSETUP, REQUEST_ESPECIALIDADES_BASE,
  RECEIVE_ESPECIALIDADES_BASE, REQUEST_ESPECIALIDADE_BASE, RECEIVE_ESPECIALIDADE_BASE, REQUEST_SAVE_ESPECIALIDADE_BASE,
  RECEIVE_SAVE_ESPECIALIDADE_BASE, REQUEST_ESPECIALIDADE_NOVA, RECEIVE_ESPECIALIDADE_NOVA,
  REQUEST_ALL_ESPECIALIDADE_PROFISSIONAL_DOCUMENTO, RECEIVE_ALL_ESPECIALIDADE_PROFISSIONAL_DOCUMENTO,
  RESET_ESPECIALIDADE_BASE, RESET_ALL_ESPECIALIDADE
} from '../constants/ActionTypes'

const INITIAL_STATE = {
  all: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
    },
    receivedAt: null,
  },
  allEspecialidadeProfissionalDocumento: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
    },
    receivedAt: null,
  },
  // autosetup
  // ---------------------------
  allAutosetup: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
    },
    receivedAt: null,
  },
  listAutosetup: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  itemAutosetup: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  saveAutosetup: {
    isLoading: false,
  },
  deleteAutosetup: {
    isLoading: false,
  },
  // ----------------------------
  list: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  item: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  ajusteValor: {
    isLoading: false,
  },
  listMacro: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  itemMacro: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  saveMacro: {
    isLoading: false,
  },
  deleteMacro: {
    isLoading: false,
  },
  // especialidades consulta (clinica)
  allTele: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
    },
    receivedAt: null,
  },
  listTele: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  itemTele: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  saveTele: {
    isLoading: false,
  },
  deleteTele: {
    isLoading: false,
  },
  // especialidades base de consulta (admin)
  listBase: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  itemBase: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  saveBase: {
    isLoading: false,
  },
  requestNew: {
    isLoading: false,
  },
}

export default function EspecialidadeReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case REQUEST_ALL_ESPECIALIDADES.type:
      return {
        ...state,
        all: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.all.data,
        }
      }
    case RECEIVE_ALL_ESPECIALIDADES.type:
      return {
        ...state,
        all: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_ALL_ESPECIALIDADE_PROFISSIONAL_DOCUMENTO:
      return {
        ...state,
        allEspecialidadeProfissionalDocumento: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.allEspecialidadeProfissionalDocumento.data,
        }
      }
    case RECEIVE_ALL_ESPECIALIDADE_PROFISSIONAL_DOCUMENTO:
      return {
        ...state,
        allEspecialidadeProfissionalDocumento: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_ALL_ESPECIALIDADES_AUTOSETUP:
      return {
        ...state,
        allAutosetup: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.allAutosetup.data,
        }
      }
    case RECEIVE_ALL_ESPECIALIDADES_AUTOSETUP:
      return {
        ...state,
        allAutosetup: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_ESPECIALIDADES_AUTOSETUP.type:
      return {
        ...state,
        listAutosetup: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.listAutosetup.data,
        }
      }
    case RECEIVE_ESPECIALIDADES_AUTOSETUP.type:
      return {
        ...state,
        listAutosetup: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_ESPECIALIDADE_AUTOSETUP.type:
      return {
        ...state,
        itemAutosetup: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.itemAutosetup.data,
        }
      }
    case RECEIVE_ESPECIALIDADE_AUTOSETUP.type:
      return {
        ...state,
        itemAutosetup: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SAVE_ESPECIALIDADE_AUTOSETUP.type:
    case RECEIVE_SAVE_ESPECIALIDADE_AUTOSETUP.type:
      return {
        ...state,
        saveAutosetup: {
          isLoading: action.isLoading,
        }
      }
    case RESET_ESPECIALIDADE_AUTOSETUP.type:
      return {
        ...state,
        itemAutosetup: INITIAL_STATE.itemAutosetup,
      }
    case REQUEST_DELETE_ESPECIALIDADE_AUTOSETUP.type:
    case RECEIVE_DELETE_ESPECIALIDADE_AUTOSETUP.type:
      return {
        ...state,
        deleteAutosetup: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_ESPECIALIDADES.type:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.list.data,
        }
      }
    case RECEIVE_ESPECIALIDADES.type:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
      case REQUEST_ESPECIALIDADE.type:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.item.data,
        }
      }
    case RECEIVE_ESPECIALIDADE.type:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
      case REQUEST_SAVE_ESPECIALIDADE.type:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: state.item.data,
          receivedAt: action.receivedAt,
        }
      }
    case RECEIVE_SAVE_ESPECIALIDADE.type:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_AJUSTE_VALOR.type:
    case RECEIVE_AJUSTE_VALOR.type:
      return {
        ...state,
        ajusteValor: {
          isLoading: action.isLoading,
        }
      }
    case RESET_ESPECIALIDADE:
      return {
        ...state,
        item: INITIAL_STATE.item,
      }
    case REQUEST_ESPECIALIDADES_MACRO.type:
      return {
        ...state,
        listMacro: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.listMacro.data,
        }
      }
    case RECEIVE_ESPECIALIDADES_MACRO.type:
      return {
        ...state,
        listMacro: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_ESPECIALIDADE_MACRO.type:
      return {
        ...state,
        itemMacro: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.itemMacro.data,
        }
      }
    case RECEIVE_ESPECIALIDADE_MACRO.type:
      return {
        ...state,
        itemMacro: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SAVE_ESPECIALIDADE_MACRO.type:
    case RECEIVE_SAVE_ESPECIALIDADE_MACRO.type:
      return {
        ...state,
        saveMacro: {
          isLoading: action.isLoading,
        }
      }
    case RESET_ESPECIALIDADE_MACRO:
      return {
        ...state,
        item: INITIAL_STATE.itemMacro,
      }
    case REQUEST_DELETE_ESPECIALIDADE_MACRO:
    case RECEIVE_DELETE_ESPECIALIDADE_MACRO:
      return {
        ...state,
        deleteMacro: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_ALL_ESPECIALIDADES_TELE.type:
      return {
        ...state,
        allTele: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.allTele.data,
        }
      }
    case RECEIVE_ALL_ESPECIALIDADES_TELE.type:
      return {
        ...state,
        allTele: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_ESPECIALIDADES_TELE.type:
      return {
        ...state,
        listTele: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.listTele.data,
        }
      }
    case RECEIVE_ESPECIALIDADES_TELE.type:
      return {
        ...state,
        listTele: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_ESPECIALIDADE_TELE.type:
      return {
        ...state,
        itemTele: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.itemTele.data,
        }
      }
    case RECEIVE_ESPECIALIDADE_TELE.type:
      return {
        ...state,
        itemTele: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SAVE_ESPECIALIDADE_TELE.type:
    case RECEIVE_SAVE_ESPECIALIDADE_TELE.type:
      return {
        ...state,
        saveTele: {
          isLoading: action.isLoading,
        }
      }
    case RESET_ESPECIALIDADE_TELE.type:
      return {
        ...state,
        itemTele: INITIAL_STATE.itemTele,
      }
    case REQUEST_DELETE_ESPECIALIDADE_TELE.type:
    case RECEIVE_DELETE_ESPECIALIDADE_TELE.type:
      return {
        ...state,
        deleteTele: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_ESPECIALIDADES_BASE.type:
      return {
        ...state,
        listBase: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.listBase.data,
        }
      }
    case RECEIVE_ESPECIALIDADES_BASE.type:
      return {
        ...state,
        listBase: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_ESPECIALIDADE_BASE.type:
      return {
        ...state,
        itemBase: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.itemBase.data,
        }
      }
    case RECEIVE_ESPECIALIDADE_BASE.type:
      return {
        ...state,
        itemBase: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SAVE_ESPECIALIDADE_BASE.type:
    case RECEIVE_SAVE_ESPECIALIDADE_BASE.type:
      return {
        ...state,
        saveBase: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_ESPECIALIDADE_NOVA:
    case RECEIVE_ESPECIALIDADE_NOVA:
      return {
        ...state,
        requestNew: {
          isLoading: action.isLoading,
        }
      }
    case RESET_ESPECIALIDADE_BASE.type:
      return {
        ...state,
        itemBase: INITIAL_STATE.itemBase,
      }
    case RESET_ALL_ESPECIALIDADE.type:
      return INITIAL_STATE
    default:
      return state
  }
}
