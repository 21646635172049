// utils
import request from '../utils/request/Request'
// constants
import { API_HOST } from '../constants/EnvTypes'
import {
  REQUEST_CONFIG, RECEIVE_CONFIG, REQUEST_FERIADOS, RECEIVE_FERIADOS, REQUEST_IMPOSTOS, RECEIVE_IMPOSTOS,
  REQUEST_SAVE_IMPOSTOS, RECEIVE_SAVE_IMPOSTOS, REQUEST_CERTIFICADO, RECEIVE_CERTIFICADO, REQUEST_SAVE_CERTIFICADO,
  RECEIVE_SAVE_CERTIFICADO, REQUEST_CONVENIOS, RECEIVE_CONVENIOS, REQUEST_CONVENIO, RECEIVE_CONVENIO,
  REQUEST_SAVE_CONVENIO, RECEIVE_SAVE_CONVENIO, REQUEST_RECEBIMENTO, RECEIVE_RECEBIMENTO, REQUEST_SAVE_RECEBIMENTO,
  RECEIVE_SAVE_RECEBIMENTO, REQUEST_SAVE_FERIADO, RECEIVE_SAVE_FERIADO, RESET_ALL_CONFIG
} from '../constants/ActionTypes'
// actions
import { showRequestError } from './AppActions'
import { fetchEntityInformacoesAdicionais } from './AuthActions'
import { addToast } from './ToastActions'

function requestConfig() {
  return {
    type: REQUEST_CONFIG,
    isLoading: true,
  }
}

function receiveConfig(key, value) {
  return {
    type: RECEIVE_CONFIG,
    isLoading: false,
    key,
    value,
  }
}

export function fetchConfig(key) {
  return dispatch => {
    dispatch(requestConfig())
    request.get(`${API_HOST}/config/key/${key}`).then(json => {
      dispatch(receiveConfig(key, json.value))
    }).catch(err => {
      dispatch(showRequestError(err))
    })
  }
}

function requestFeriados() {
  return {
    type: REQUEST_FERIADOS,
    isLoading: true,
  }
}

function receiveFeriados(data) {
  return {
    type: RECEIVE_FERIADOS,
    isLoading: false,
    data,
    receivedAt: Date.now(),
  }
}

export function fetchFeriados(ano) {
  return dispatch => {
    dispatch(requestFeriados())
    request.get(`${API_HOST}/config/feriados/${ano}`).then(json => {
      dispatch(receiveFeriados(json))
    }).catch(err => {
      dispatch(receiveFeriados())
      dispatch(showRequestError(err))
    })
  }
}

function requestSaveFeriado() {
  return {
    type: REQUEST_SAVE_FERIADO,
    isLoading: true,
  }
}

function receiveSaveFeriado() {
  return {
    type: RECEIVE_SAVE_FERIADO,
    isLoading: false,
  }
}

/**
 * Persiste configuração de um feriado
 *
 * @param {Object} data
 * @param {Function} callback
 */
export function fetchSaveFeriado(data, callback = () => {}) {
  return dispatch => {
    dispatch(requestSaveFeriado())
    request.put(`${API_HOST}/config/feriado`, {}, JSON.stringify(data)).then(json => {
      dispatch(receiveSaveFeriado(json))
      dispatch(addToast('success', 'Feriado salvo com sucesso.', null, true))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestImpostos() {
  return {
    type: REQUEST_IMPOSTOS.type,
    isLoading: true,
  }
}

function receiveImpostos(data) {
  return {
    type: RECEIVE_IMPOSTOS.type,
    isLoading: false,
    data,
    receivedAt: Date.now(),
  }
}

export function fetchImpostos(callback = () => {}) {
  return dispatch => {
    dispatch(requestImpostos())
    const requestId = REQUEST_IMPOSTOS.id
    request.get(`${API_HOST}/config/impostos`, {}, { requestId: requestId }).then(json => {
      dispatch(receiveImpostos(json))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err, requestId))
      callback(false)
    })
  }
}

function requestSaveImpostos() {
  return {
    type: REQUEST_SAVE_IMPOSTOS,
    isLoading: true,
  }
}

function receiveSaveImpostos() {
  return {
    type: RECEIVE_SAVE_IMPOSTOS,
    isLoading: false,
  }
}

/**
 * Persiste configurações de impostos
 *
 * @param {Object} data
 * @param {Function} callback
 */
export function fetchSaveImpostos(data, callback = () => {}) {
  return dispatch => {
    dispatch(requestSaveImpostos())
    const requestId = REQUEST_SAVE_IMPOSTOS.id
    request.put(`${API_HOST}/config/impostos`, {}, JSON.stringify(data), { requestId: requestId }).then(json => {
      dispatch(receiveSaveImpostos(json))
      dispatch(addToast('success', 'Configuração de impostos salva com sucesso.', null, true))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err, requestId))
      callback(false)
    })
  }
}

function requestCertificado() {
  return {
    type: REQUEST_CERTIFICADO.type,
    isLoading: true,
  }
}

function receiveCertificado(data) {
  return {
    type: RECEIVE_CERTIFICADO.type,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchCertificado(callback = () => {}) {
  return dispatch => {
    dispatch(requestCertificado())
    const requestId = REQUEST_CERTIFICADO.id
    request.get(`${API_HOST}/config/certificado`, {}, { requestId: requestId }).then(json => {
      dispatch(receiveCertificado(json))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err, requestId))
      callback(false)
    })
  }
}

function requestSaveCertificado() {
  return {
    type: REQUEST_SAVE_CERTIFICADO,
    isLoading: true,
  }
}

function receiveSaveCertificado() {
  return {
    type: RECEIVE_SAVE_CERTIFICADO,
    isLoading: false,
  }
}

export function fetchSaveCertificado(data, callback = () => {}) {
  return dispatch => {
    dispatch(requestSaveCertificado())
    const requestId = REQUEST_SAVE_CERTIFICADO.id
    request.put(`${API_HOST}/config/certificado`, {}, JSON.stringify(data), { requestId }).then(json => {
      dispatch(receiveSaveCertificado(json))
      dispatch(addToast('success', 'Certificado digital configurado com sucesso.', null, true))
      dispatch(fetchEntityInformacoesAdicionais())
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      // dispatch(addToast('error', 'Formato do certificado inválido. Se o problema persistir, consulte o suporte', null, true))
      callback(false)
    })
  }
}

export function fetchRemoveCertificado(callback = () => {}) {
  return dispatch => {
    dispatch(requestSaveCertificado())
    const requestId = REQUEST_SAVE_CERTIFICADO.id
    request.delete(`${API_HOST}/config/certificado`, {}, null, { requestId }).then(json => {
      dispatch(receiveSaveCertificado(json))
      dispatch(addToast('success', 'Certificado digital removido com sucesso.', null, true))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestConvenios() {
  return {
    type: REQUEST_CONVENIOS,
    isLoading: true,
  }
}

function receiveConvenios(data) {
  return {
    type: RECEIVE_CONVENIOS,
    isLoading: false,
    data,
    receivedAt: Date.now(),
  }
}

export function fetchConvenios(params = { page: 1, limit: 50, orderBy: 'id', orderDirection: 'DESC' }) {
  return dispatch => {
    dispatch(requestConvenios())
    request.get(`${API_HOST}/config/convenios`, params).then(json => {
      dispatch(receiveConvenios(json))
    }).catch(err => {
      dispatch(receiveConvenios())
      dispatch(showRequestError(err))
    })
  }
}

function requestConvenio() {
  return {
    type: REQUEST_CONVENIO,
    isLoading: true,
  }
}

function receiveConvenio(data) {
  return {
    type: RECEIVE_CONVENIO,
    isLoading: false,
    data,
    receivedAt: Date.now(),
  }
}

export function fetchConvenio(idClinicaParceira, idEspecialidadeTele) {
  return dispatch => {
    dispatch(requestConvenio())
    request.get(`${API_HOST}/config/convenio/${idClinicaParceira}/${idEspecialidadeTele}`).then(json => {
      dispatch(receiveConvenio(json))
    }).catch(err => {
      dispatch(receiveConvenio())
      dispatch(showRequestError(err))
    })
  }
}

function requestSaveConvenio() {
  return {
    type: REQUEST_SAVE_CONVENIO,
    isLoading: true,
  }
}

function receiveSaveConvenio() {
  return {
    type: RECEIVE_SAVE_CONVENIO,
    isLoading: false,
  }
}

export function fetchSaveConvenio(data) {
  return dispatch => {
    dispatch(requestSaveConvenio())
    request.post(`${API_HOST}/config/convenio`, {}, JSON.stringify(data)).then(json => {
      dispatch(receiveSaveConvenio(json))
    }).catch(err => {
      dispatch(receiveSaveConvenio())
      dispatch(showRequestError(err))
    })
  }
}

function requestRecebimento() {
  return {
    type: REQUEST_RECEBIMENTO,
    isLoading: true,
  }
}

function receiveRecebimento(data) {
  return {
    type: RECEIVE_RECEBIMENTO,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchRecebimento(callback = () => {}) {
  return dispatch => {
    dispatch(requestRecebimento())
    request.get(`${API_HOST}/config/recebimento`).then(json => {
      dispatch(receiveRecebimento(json))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      dispatch(receiveRecebimento())
      callback(false)
    })
  }
}

function requestSaveRecebimento() {
  return {
    type: REQUEST_SAVE_RECEBIMENTO,
    isLoading: true,
  }
}

function receiveSaveRecebimento() {
  return {
    type: RECEIVE_SAVE_RECEBIMENTO,
    isLoading: false,
  }
}

export function fetchSaveRecebimento(data, callback = () => {}) {
  return dispatch => {
    dispatch(requestSaveRecebimento())
    request.put(`${API_HOST}/config/recebimento`, {}, JSON.stringify(data)).then(json => {
      dispatch(receiveSaveRecebimento(json))
      dispatch(addToast('success', 'Configurações salvas com sucesso.', null, true))
      dispatch(fetchEntityInformacoesAdicionais())
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      dispatch(receiveSaveRecebimento())
      callback(false)
    })
  }
}

export function resetAllConfig() {
  return {
    type: RESET_ALL_CONFIG.type,
  }
}
